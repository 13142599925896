import React from 'react'
import {
    List, Datagrid, TextField, Create, Edit, SimpleForm,
    TextInput, 
    required
} from 'react-admin'

export const BuyerList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="pib" />
            <TextField source="mat_br" />
            <TextField source="city" />
            <TextField source="zip" />
        </Datagrid>
    </List>
);

export const BuyerCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="phone" validate={[required()]} />
            <TextInput source="pib" validate={[required()]} />
            <TextInput source="mat_br" validate={[required()]} />
            <TextInput source="city" validate={[required()]} />
            <TextInput source="zip" validate={[required()]} />
        </SimpleForm>
    </Create>
);

export const BuyerEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="name" validate={[required()]} />
            <TextInput source="phone" validate={[required()]} />
            <TextInput source="pib" validate={[required()]} />
            <TextInput source="mat_br" validate={[required()]} />
            <TextInput source="city" validate={[required()]} />
            <TextInput source="zip" validate={[required()]} />            
        </SimpleForm>
    </Edit>
);

