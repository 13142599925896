import React from 'react'
import {
    List, Datagrid, TextField, NumberField, SelectInput, DateInput, EditButton,
    Show, Create, Edit, SimpleForm, TopToolbar, Button, Link,
    BooleanInput, TextInput, NumberInput, BooleanField, DateField,
    SimpleShowLayout, SaveButton, DeleteButton,
    ReferenceManyField, ReferenceInput, required, FieldTitle,
    minValue, maxValue, number
} from 'react-admin'
import { useMutation, useTranslate, useRefresh } from 'react-admin'

import { Box, Button as CoreButton } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'

import MuiToolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

import classnames from 'classnames'
import { parse } from "query-string"

import PrintIcon from '@material-ui/icons/Print'
import UnarchiveIcon from '@material-ui/icons/Unarchive'


const useStyles = makeStyles(
    theme => ({
        toolbar: {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
        },
        desktopToolbar: {
            marginTop: theme.spacing(2),
        },
        mobileToolbar: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px',
            width: '100%',
            boxSizing: 'border-box',
            flexShrink: 0,
            zIndex: 2,
        },
        defaultToolbar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
        },
        spacer: {
            [theme.breakpoints.down('xs')]: {
                height: '5em',
            },
        },
        inlineBlock: {
            display: 'inline-flex',
            marginRight: '1rem'
        },
        label: {
            position: 'relative',
            padding: '8px 0 4px',
        },
        actionButtonSpace: {
            paddingLeft: '0.4em'
        }
    }),
    { name: 'RaToolbar' }
)

export const OrderList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField source="order_dt" />
            <TextField source="full_name" />
            <NumberField source="total" />
            <BooleanField source="archived" />
        </Datagrid>
    </List>
)

export const OrderShow = props => {
    const classes = useStyles()

    return (
        <Show actions={<CustomerShowActions role={props.permissions} />} {...props}>
            <SimpleShowLayout>
                <TextField source="id" className={classes.inlineBlock} />
                <DateField source="order_dt" className={classes.inlineBlock} />

                <CustomerInfoShow />

                <BooleanField source="archived" />
                <NumberField source="total" />
                <ReferenceManyField reference="order-lines" target="order_id" addLabel={false} fullWidth={true}>
                    <Datagrid>
                        <TextField source="product_name" />
                        <NumberField source="price" />
                        <NumberField source="qty" />
                        <NumberField source="amount" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
}

const CustomerInfoShow = props => {
    const classes = useStyles()
    const translate = useTranslate()

    return (
        <Box display="flex" flexWrap="wrap" mb="0.5em">
            <Box mr="1em">
                <InputLabel shrink className={classes.label}>
                    <FieldTitle label={translate('resources.customers.fields.first_name')} />
                </InputLabel>
                <TextField {...props} source="first_name" />
            </Box>
            <Box mr="1em">
                <InputLabel shrink className={classes.label}>
                    <FieldTitle label={translate('resources.customers.fields.last_name')} />
                </InputLabel>
                <TextField {...props} source="last_name" />
            </Box>
            <Box mr="1em">
                <InputLabel shrink className={classes.label}>
                    <FieldTitle label={translate('resources.customers.fields.phone')} />
                </InputLabel>
                <TextField {...props} source="phone" />
            </Box>
            <Box mr="1em">
                <InputLabel shrink className={classes.label}>
                    <FieldTitle label={translate('resources.customers.fields.reg_num')} />
                </InputLabel>
                <TextField {...props} source="reg_num" />
            </Box>
        </Box>
    )
}

const CustomerShowActions = ({ basePath, data, resource, role }) => {
    const classes = useStyles()
    const translate = useTranslate()
    const refresh = useRefresh()

    let archived = false
    if (data && data.archived) {
        archived = true
    }

    const [print, { printLoading }] = useMutation(
        {
            type: 'print',
            resource: 'orders',
            payload: { id: data ? data.id : 0, data: {} }
        },
        {
            onSuccess: response => {
                showFile(response.data, data.id)
                refresh()
            }
        }  
    )

    const showFile = (blob, id) => {
        var newBlob = new Blob([blob], { type: "application/pdf" })
    
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = "prijemnica-" + id + ".pdf"
        link.click()
    }

    const [unarchive, { unarchiveLoading }] = useMutation(
        {
            type: 'rpc',
            resource: 'orders',
            payload: { id: data ? data.id : 0, data: { action: 'unarchive' } }
        },
        {
            onSuccess: response => {
                refresh()
            }
        }
    )


    return (
        <TopToolbar>
            {!archived &&
                <EditButton basePath={basePath} record={data} />
            }
            <CoreButton size="small" color="primary" onClick={print} disabled={printLoading} >
                <PrintIcon />
                <span className={classes.actionButtonSpace} >{translate('PRINT')}</span>
            </CoreButton>
            {role === 'MANAGER' && archived &&
                <CoreButton size="small" color="primary" onClick={unarchive} disabled={unarchiveLoading} >
                    <UnarchiveIcon />
                    <span className={classes.actionButtonSpace} >{translate('UNARCHIVE')}</span>
                </CoreButton>
            }
        </TopToolbar>
    )
}


export const OrderCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm>
                <CustomerInfoInput />
            </SimpleForm>
        </Create>
    )
}

export const OrderEdit = props => {
    const classes = useStyles()
    const redirect = (basePath, id) => `/orders/${id}/show`

    return (
        <Edit {...props} undoable={false}>
            <SimpleForm redirect={redirect}>
                <TextInput source="id" disabled formClassName={classes.inlineBlock} />
                <DateInput source="order_dt" formClassName={classes.inlineBlock} />

                <CustomerInfoInput />

                <BooleanInput source="archived" disabled />

                <ReferenceManyField reference="order-lines" target="order_id" addLabel={false} fullWidth={true}>
                    <Datagrid>
                        <TextField source="product_name" />
                        <TextField source="price" />
                        <TextField source="qty" />
                        <TextField source="amount" />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
                <OrderLineAddButton />
                <br />

                <TextInput source="total" disabled />

            </SimpleForm>
        </Edit>
    )
}

const CustomerInfoInput = props => {
    const translate = useTranslate()

    return (
        <Box display="flex" flexWrap="wrap">
            <Box mr="1em">
                <TextInput {...props} source="first_name" validate={[required()]} label={translate('resources.customers.fields.first_name')} />
            </Box>
            <Box mr="1em">
                <TextInput {...props} source="last_name" label={translate('resources.customers.fields.last_name')} />
            </Box>
            <Box mr="1em">
                <TextInput {...props} source="phone" label={translate('resources.customers.fields.phone')} validate={[required()]} />
            </Box>
            <Box mr="1em">
                <TextInput {...props} source="reg_num" validate={[required()]} label={translate('resources.customers.fields.reg_num')} />
            </Box>
        </Box>
    )
}

const OrderLineAddButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: `/order-lines/create`,
            search: `?order_id=${record.id}`,
        }}
        label="Dodaj proizvod"
    >
    </Button>
)

export const OrderLineCreate = props => {
    const redirect = (basePath, id, data) => `/orders/${data.order_id}`
    const { order_id: order_id_string } = parse(props.location.search)

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect} undoable={false} defaultValue={{ order_id: parseInt(order_id_string) }}>
                <ReferenceInput source="product_id" reference="products" perPage={500} sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput>
                <NumberInput source="qty" initialValue={1} validate={[required(), number(), minValue(1), maxValue(99)]} step={1} min={1} max={99} />
            </SimpleForm>
        </Create>
    )
}

export const OrderLineEdit = props => {
    const redirect = (basePath, id, data) => `/orders/${data.order_id}`

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect} undoable={false} toolbar={<OrderLineEditToolbar />}>
                <TextInput source="id" disabled />
                <ReferenceInput source="product_id" reference="products" perPage={500} sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput source="qty" />
                <TextInput source="price" disabled />
            </SimpleForm>
        </Edit>
    )
}

const OrderLineEditToolbar = props => {
    const { record } = props
    const redirect = (basePath, id) => `/orders/${record.order_id}/show`

    const classes = useStyles(props)

    return (
        <MuiToolbar
            className={classnames(
                classes.toolbar,
                {
                    [classes.mobileToolbar]: props.width === 'xs',
                    [classes.desktopToolbar]: props.width !== 'xs',
                },
            )}
            role="toolbar"
        >
            <div className={classes.defaultToolbar}>
                <SaveButton {...props} />
                {record && typeof record.id !== 'undefined' && (
                    <DeleteButton {...props} undoable={false} redirect={redirect} />
                )}

            </div>
        </MuiToolbar>
    )
}
