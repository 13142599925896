import React from 'react'
import {
    List, Datagrid, TextField, Create, Edit, SimpleForm,
    TextInput, 
    required
} from 'react-admin'

export const ProductList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="purchase_price" />
            <TextField source="sale_price" />
        </Datagrid>
    </List>
);

export const ProductCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="purchase_price" validate={[required()]} />
            <TextInput source="sale_price" validate={[required()]} />
        </SimpleForm>
    </Create>
);

export const ProductEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="name" validate={[required()]} />
            <TextInput source="purchase_price" validate={[required()]} />
            <TextInput source="sale_price" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

