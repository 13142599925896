module.exports = {
    resources: {
        users: {
            name: 'Korisnik |||| Korisnici',
            fields: {
                username: 'Korisničko ime',
                phone: 'Telefon',
                role: 'Uloga',
                last_login: 'Poslednja prijava',
                active: 'Aktivan',
            },
        },
        products: {
            name: 'Proizvod |||| Proizvodi',
            fields: {
                name: 'Naziv',
                purchase_price: 'Nabavna cena',
                sale_price: 'Prodajna cena',
            }
        },
        customers: {
            name: 'Kupac |||| Kupci',
            fields: {
                first_name: 'Ime',
                last_name: 'Prezime',
                phone: 'Telefon',
                reg_num: 'Matični broj',
            }
        },
        buyers: {
            name: 'Otkupljivač |||| Otkupljivači',
            fields: {
                name: 'Naziv',
                phone: 'Telefon',
                mat_br: 'Matični broj',                
                city: 'Grad',                
                zip: 'PTT broj',                
            }
        },
        orders: {
            name: 'Prijemnica |||| Prijemnice',
            fields: {
                order_dt: 'Datum',
                archived: 'Arhivirano',
                total: 'Ukupan iznos',
            }
        },
        'order-lines': {
            name: 'Stavke prijemnice |||| Stavke prijemnica',
            fields: {
                product_name: 'Naziv proizvoda',
                price: 'Cena',
                qty: 'Količina',
                amount: 'Iznos',
            }
        },
        invoices: {
            name: 'Faktura |||| Fakture',
            fields: {
                invoice_dt: 'Datum fakture',
                from_dt: 'Od datuma',
                to_dt: 'Do datuma',                
                total: 'Ukupan iznos',
                auto: 'Automatski kreiraj'
            }
        },
        'invoice-lines': {
            name: 'Stavke fakture |||| Stavke faktura',
            fields: {
                product_name: 'Naziv proizvoda',
                price: 'Cena',
                qty: 'Količina',
                amount: 'Iznos',
            }
        }
    },
    WORKER: 'Radnik',
    MANAGER: 'Menadžer',
    PRINT: 'Štampa',
    UNARCHIVE: 'Dearhiviraj',
}
