import React from 'react'
import {
    List, Datagrid, TextField, SelectInput, DateInput, Create, Edit, SimpleForm,
    BooleanInput, TextInput, BooleanField, EmailField, DateField, PasswordInput,
    required, email
} from 'react-admin'
import { useTranslate } from 'react-admin'


const TranslatedTextField = ({ source, record = {} }) => {
    const translate = useTranslate()

    return (
        <span>{translate(record[source])}</span>
    )
}

export const UserList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="username" />
            <EmailField source="email" />
            <TextField source="phone" />
            <TranslatedTextField source="role" />
            <DateField source="last_login" />
            <BooleanField source="active" />
        </Datagrid>
    </List>
)


export const UserCreate = props => {
    const translate = useTranslate()

    return (
        <Create {...props}>
            <SimpleForm>
                <BooleanInput source="active" initialValue={true} />
                <TextInput source="username" validate={[required()]} />
                <PasswordInput source="password" initiallyVisible validate={[required()]} />
                <TextInput source="email" validate={[required(), email()]} />
                <TextInput source="phone" validate={[required()]} />
                <SelectInput source="role" choices={[
                    { id: 'WORKER', name: translate('WORKER') },
                    { id: 'MANAGER', name: translate('MANAGER') },
                ]} validate={[required()]} />
            </SimpleForm>
        </Create>
    )
}

export const UserEdit = props => {
    const translate = useTranslate()

    return (
        <Edit {...props}>
            <SimpleForm>
                <BooleanInput source="active" />
                <TextInput source="id" disabled />
                <TextInput source="username" validate={[required()]} />
                <PasswordInput source="password" initiallyVisible />
                <TextInput source="email" validate={[required(), email()]} />
                <TextInput source="phone" validate={[required()]} />
                <SelectInput source="role" choices={[
                    { id: 'WORKER', name: translate('WORKER') },
                    { id: 'MANAGER', name: translate('MANAGER') },
                ]} />
                <DateInput source="last_login" disabled />
            </SimpleForm>
        </Edit>
    )
}