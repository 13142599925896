import * as jwt_decode from "jwt-decode"

const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(process.env.REACT_APP_API_URL + '/authenticate', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(async response => {
                if (response.status < 200 || response.status >= 300) {
                    if (response.status === 401) {
                        await refreshToken()
                    } else {
                        throw new Error(response.statusText);
                    }
                }
                return response.json()
            })
            .then(({ access_token, refresh_token }) => {
                localStorage.setItem('access_token', access_token )
                localStorage.setItem('refresh_token', refresh_token )
            })
    },
    logout: () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        return Promise.resolve()
    },
    checkAuth: () => {
        return localStorage.getItem('access_token')
        ? Promise.resolve()
        : Promise.reject()
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401) {
            return refreshToken()
        }
        if (status === 403) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')

            return Promise.reject()
        }
        return Promise.resolve()
    },
    getPermissions: params => {
        const decodedToken = jwt_decode(localStorage.getItem('access_token'))
        const role = decodedToken.user_claims.role
        return role ? Promise.resolve(role) : Promise.reject()
    }
};

const refreshToken = async ()  => {
    const refreshToken = localStorage.getItem('refresh_token');

    console.log('fetch refresh')
    const request = new Request(process.env.REACT_APP_API_URL + '/refresh_token', {
        method: 'POST',
        body: JSON.stringify({ "refresh": refreshToken }),
        headers: new Headers({ 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('refresh_token')
        }),
    })

    const response = await fetch(request)
    if (response.status !== 200) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        throw new Error(response.statusText)
    }

    const resObj = await response.json()
    localStorage.setItem('access_token', resObj.access_token);
}

export default authProvider
