import { fetchUtils } from 'react-admin'
import { stringify } from 'query-string'

const apiUrl = process.env.REACT_APP_API_URL
const httpClient = fetchUtils.fetchJson

const getOptions = () => {
    const options = {}
    options.headers = new Headers({ Accept: 'application/json' });
    options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
    return options
}

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`

        return httpClient(url, getOptions()).then(({ headers, json }) => {
            return {
                data: json,
                total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
            }
        })
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, getOptions()).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, getOptions()).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`

        return httpClient(url, getOptions()).then(({ headers, json }) => ({
            headers: getOptions().headers,
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }))
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            headers: getOptions().headers,
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            headers: getOptions().headers,
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            headers: getOptions().headers,
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: getOptions().headers,
        }).then(() => ({ data: {} })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }

        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            headers: getOptions().headers,
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    },

    print: (resource, params) => {
        if (resource !== 'orders' && resource !== 'invoices') {
            return
        }

        const url = `${apiUrl}/${resource}/${params.id}/print`

        return fetch(url, { method: 'POST', headers: getOptions().headers })
            .then(r => r.blob())
            .then(blob => ({ data: blob }))
    },

    rpc: (resource, params) => {
        if (resource !== 'orders') {
            return
        }

        const url = `${apiUrl}/${resource}/${params.id}/${params.data.action}`

        return httpClient(url, {
            method: 'POST',
            headers: getOptions().headers,
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    }
}
