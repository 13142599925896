import React from 'react'
import {
    List, Datagrid, TextField, NumberField, SelectInput, DateInput, EditButton,
    Show, Create, Edit, SimpleForm, TopToolbar, Button, Link,
    TextInput, NumberInput, DateField, BooleanInput,
    SimpleShowLayout, SaveButton, DeleteButton,
    ReferenceManyField, ReferenceInput, required,
    minValue, maxValue, number
} from 'react-admin'
import { useMutation, useTranslate, useRefresh } from 'react-admin'

import { Box, Button as CoreButton } from '@material-ui/core'

import MuiToolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

import classnames from 'classnames'
import { parse } from "query-string"

import PrintIcon from '@material-ui/icons/Print'


const useStyles = makeStyles(
    theme => ({
        toolbar: {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
        },
        desktopToolbar: {
            marginTop: theme.spacing(2),
        },
        mobileToolbar: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px',
            width: '100%',
            boxSizing: 'border-box',
            flexShrink: 0,
            zIndex: 2,
        },
        defaultToolbar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
        },
        spacer: {
            [theme.breakpoints.down('xs')]: {
                height: '5em',
            },
        },
        inlineBlock: {
            display: 'inline-flex',
            marginRight: '1rem'
        },
        label: {
            position: 'relative',
            padding: '8px 0 4px',
        },
        actionButtonSpace: {
            paddingLeft: '0.4em'
        }
    }),
    { name: 'RaToolbar' }
)

export const InvoiceList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField source="invoice_dt" />
            <DateField source="from_dt" />
            <DateField source="to_dt" />
            <TextField source="name" />
            <NumberField source="total" />
        </Datagrid>
    </List>
)

export const InvoiceShow = props => {
    const classes = useStyles()

    return (
        <Show actions={<CustomerShowActions role={props.permissions} />} {...props}>
            <SimpleShowLayout>
                <TextField source="id" className={classes.inlineBlock} />
                <DateField source="invoice_dt" className={classes.inlineBlock} />
                <DateField source="from_dt" className={classes.inlineBlock} />
                <DateField source="to_dt" className={classes.inlineBlock} />

                <NumberField source="total" />
                <ReferenceManyField reference="invoice-lines" target="invoice_id" addLabel={false} fullWidth={true}>
                    <Datagrid>
                        <TextField source="product_name" />
                        <NumberField source="price" />
                        <NumberField source="qty" />
                        <NumberField source="amount" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
}

const CustomerShowActions = ({ basePath, data, resource, role }) => {
    const classes = useStyles()
    const translate = useTranslate()
    const refresh = useRefresh()

    const [print, { printLoading }] = useMutation(
        {
            type: 'print',
            resource: 'invoices',
            payload: { id: data ? data.id : 0, data: {} }
        },
        {
            onSuccess: response => {
                showFile(response.data, data.id)
                refresh()
            }
        }
    )

    const showFile = (blob, id) => {
        var newBlob = new Blob([blob], { type: "application/pdf" })

        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = "racun-" + id + ".pdf"
        link.click()
    }

    return (
        <TopToolbar>
            { role === 'MANAGER' && 
                <EditButton basePath={basePath} record={data} />
            }
            <CoreButton size="small" color="primary" onClick={print} disabled={printLoading} >
                <PrintIcon />
                <span className={classes.actionButtonSpace} >{translate('PRINT')}</span>
            </CoreButton>
        </TopToolbar>
    )
}


export const InvoiceCreate = props => {
    let createForm = (
        <Create {...props}>
            <SimpleForm redirect="show">
                <ReferenceInput label="Otkupljivač" source="buyer_id" reference="buyers">
                    <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput>
                <BooleanInput source="auto" disabled initialValue={true} />
            </SimpleForm>
        </Create>
    )

    if (props.permissions === 'MANAGER') {
        createForm = (
            <Create {...props}>
                <SimpleForm>
                    <ReferenceInput source="buyer_id" reference="buyers">
                        <SelectInput optionText="name" validate={[required()]} />
                    </ReferenceInput>
                    <DateInput source="from_dt" validate={[required()]} />
                    <DateInput source="to_dt" validate={[required()]} />
                </SimpleForm>
            </Create>

        )
    }

    return createForm
}

export const InvoiceEdit = props => {
    const classes = useStyles()
    const redirect = (basePath, id) => `/invoices/${id}/show`

    return (
        <Edit {...props} undoable={false}>
            <SimpleForm redirect={redirect}>
                <TextInput source="id" disabled formClassName={classes.inlineBlock} />
                <DateInput source="invoice_dt" formClassName={classes.inlineBlock} />
                <DateInput source="from_dt" formClassName={classes.inlineBlock} disabled />
                <DateInput source="to_dt" formClassName={classes.inlineBlock} disabled />

                <ReferenceManyField reference="invoice-lines" target="invoice_id" addLabel={false} fullWidth={true}>
                    <Datagrid>
                        <TextField source="product_name" />
                        <NumberField source="price" />
                        <NumberField source="qty" />
                        <NumberField source="amount" />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
                <InvoiceLineAddButton />
                <br />

                <TextInput source="total" disabled />

            </SimpleForm>
        </Edit>
    )
}

const InvoiceLineAddButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: `/invoice-lines/create`,
            search: `?invoice_id=${record.id}`,
        }}
        label="Dodaj proizvod"
    >
    </Button>
)

export const InvoiceLineCreate = props => {
    const redirect = (basePath, id, data) => `/invoices/${data.invoice_id}`
    const { invoice_id: invoice_id_string } = parse(props.location.search)

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect} undoable={false} defaultValue={{ invoice_id: parseInt(invoice_id_string) }}>
                <ReferenceInput source="product_id" reference="products">
                    <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput>
                <NumberInput source="qty" initialValue={1} validate={[required(), number(), minValue(1), maxValue(99)]} step={1} min={1} max={99} />
            </SimpleForm>
        </Create>
    )
}

export const InvoiceLineEdit = props => {
    const redirect = (basePath, id, data) => `/invoices/${data.invoice_id}`

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect} undoable={false} toolbar={<InvoiceLineEditToolbar />}>
                <TextInput source="id" disabled />
                <ReferenceInput source="product_id" reference="products">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput source="qty" />
                <TextInput source="price" disabled />
            </SimpleForm>
        </Edit>
    )
}

const InvoiceLineEditToolbar = props => {
    const { record } = props
    const redirect = (basePath, id) => `/invoices/${record.invoice_id}/show`

    const classes = useStyles(props)

    return (
        <MuiToolbar
            className={classnames(
                classes.toolbar,
                {
                    [classes.mobileToolbar]: props.width === 'xs',
                    [classes.desktopToolbar]: props.width !== 'xs',
                },
            )}
            role="toolbar"
        >
            <div className={classes.defaultToolbar}>
                <SaveButton {...props} />
                {record && typeof record.id !== 'undefined' && (
                    <DeleteButton {...props} undoable={false} redirect={redirect} />
                )}

            </div>
        </MuiToolbar>
    )
}
