module.exports = {
    ra: {
        action: {
            add_filter: 'Dodaj filter',
            add: 'dodaj',
            back: 'Vrati se',
            bulk_actions: '1 stavka izabrana |||| %{smart_count} stavke izabrane',
            cancel: 'Odustani',
            clear_input_value: 'Očisti vrednost',
            clone: 'Kloniranje',
            confirm: 'Potvrdi',
            create: 'Kreiranje',
            delete: 'Briši',
            edit: 'Izmeni',
            export: 'Eksport',
            list: 'Lista',
            refresh: 'Osveži',
            remove_filter: 'Ukloni ovaj filter',
            remove: 'Ukloni',
            save: 'Snimi',
            search: 'Pretraga',
            show: 'Prikaz',
            sort: 'Sortiranje',
            undo: 'Poništi',
            expand: 'Proširi',
            close: 'Zatvori',
            open_menu: 'Otvori meni',
            close_menu: 'Zatvori meni',
        },
        boolean: {
            true: 'Da',
            false: 'Ne',
            null: '',
        },
        page: {
            create: 'Kreiraj %{name}',
            dashboard: 'Glavna tabla',
            edit: '%{name} #%{id}',
            error: 'Desila se neka graška',
            list: '%{name}',
            loading: 'Učitavanje',
            not_found: 'Nije pronađeno',
            show: '%{name} #%{id}',
            empty: '%{name} ne postoji.',
            invite: 'Da li želite da dodate jednu?',
        },
        input: {
            file: {
                upload_several: 'Prevucite nekoliko fajlova za upload, ili kliknite da biste izabrali fajlove.',
                upload_single: 'Prevucite fajl za upload, ili kliknite da biste izabrali fajl.',
            },
            image: {
                upload_several: 'Prevucite nekoliko slika za upload, ili kliknite da biste izabrali slike.',
                upload_single: 'Prevucite sliku za upload, ili kliknite da biste izabrali sliku.',
            },
            references: {
                all_missing: 'Nije moguće pronaći podatke referenci.',
                many_missing: 'Ne postoji bar jedna povezana referenca.',
                single_missing: 'Povezana referenca ne postoji.',
            },
            password: {
                toggle_visible: 'Sakrij lozinku',
                toggle_hidden: 'Prikaži lozinku',
            },
        },
        message: {
            about: 'O',
            are_you_sure: 'Da li ste sigurni?',
            bulk_delete_content: 'Da li ste sigurni da želite obrisati %{name}? |||| Da li ste sigurni da želite obrisati %{smart_count} items?',
            bulk_delete_title: 'Brisanje %{name} |||| Brisanje %{smart_count} %{name}',
            delete_content: 'Da li ste sigurni da želite obrisati ovu stavku?',
            delete_title: 'Brisanje %{name} #%{id}',
            details: 'Detalji',
            error: "Desila se greška u brauseru i Vaš upit ne može biti završen.",
            invalid_form: 'Forma nije ispravna. Molimo proverite postojeće greške',
            loading: 'Stranica se učitava, molimo sačekajte',
            no: 'Ne',
            not_found: 'Ili ste uneli pogrešnu veb adresu, ili ste kliknuli na loš link.',
            yes: 'Da',
            unsaved_changes: "Neke Vaše promene nisu sačuvane. Da li ste sigurni, da želite da ih izgubite?",
        },
        navigation: {
            no_results: 'Nema pronađenih rezultata',
            no_more_results: 'Broj stranince %{page} je izvan granica. Pokušajte prethodnu stranu.',
            page_out_of_boundaries: 'Stranica broj %{page} je izvan granica',
            page_out_from_end: 'Ne može da se otvori stranica posle poslednje',
            page_out_from_begin: 'Ne može da se otvori stranica pre 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
            page_rows_per_page: 'Reda po strani:',
            next: 'Sledeće',
            prev: 'Prethodno',
        },
        auth: {
            auth_check_error: 'Molimo Vas prijavite se (ulogujte) da biste nastavili',
            user_menu: 'Profil',
            username: 'Korisničko ime',
            password: 'Lozinka',
            sign_in: 'Prijava',
            sign_in_error: 'Greška prilikom prijave, molimo pokušajte ponovo',
            logout: 'Odjava',
        },
        notification: {
            updated: 'Slog je snimljen |||| %{smart_count} slogova je snimljen',
            created: 'Slog je kreiran',
            deleted: 'Slog je obrisan |||| %{smart_count} slogova obrisano',
            bad_item: 'Neispravan slog',
            item_doesnt_exist: 'Slog ne postoji',
            http_error: 'Greška u komunikaciji sa serverom',
            data_provider_error: 'dataProvider greška. Pogledajte konzolu za opširnije.',
            i18n_error: 'Problem kod učitavanja prevoda za izabrani jezik',
            canceled: 'Akcija je poništena',
            logged_out: 'Vaša sesija se završila, molimo da se ponovo povežete.',
        },
        validation: {
            required: 'Obavezno',
            minLength: 'Mora biti minimum %{min} karaktera',
            maxLength: 'Mora biti maksimum %{max} karaktera',
            minValue: 'Mora biti minimum %{min}',
            maxValue: 'Mora biti maksimum %{max}',
            number: 'Mora biti broj',
            email: 'Mora biti ispravan email',
            oneOf: 'Mora biti jedna od: %{options}',
            regex: 'Mora da se poklapa sa šablonom karaktera (regexp): %{pattern}',
        },
    },
}
