import React from 'react'
import serbianUIMessages from './language/SerbianUIMessages'
import serbianDomainMessages from './language/SerbianDomainMessages'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import { Admin, Resource, ShowGuesser, ListGuesser, EditGuesser } from 'react-admin'
import dataProvider from './dataProvider'
import authProvider from './authProvider'

import { UserList, UserCreate, UserEdit } from './components/User'
import { ProductList, ProductCreate, ProductEdit } from './components/Product'
import { BuyerList, BuyerCreate, BuyerEdit } from './components/Buyer'
import { OrderList, OrderShow, OrderCreate, OrderEdit, OrderLineCreate, OrderLineEdit } from './components/Order'
import { InvoiceList, InvoiceShow, InvoiceCreate, InvoiceEdit, InvoiceLineCreate, InvoiceLineEdit } from './components/Invoice'

const messages = {
  'sr': { ...serbianUIMessages, ...serbianDomainMessages },
};
const i18nProvider = polyglotI18nProvider(locale => messages[locale], "sr")

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider}>
    {permissions => [
      <Resource name="orders" show={OrderShow} list={OrderList} create={OrderCreate} edit={OrderEdit} />,
      <Resource name="order-lines" create={OrderLineCreate} edit={OrderLineEdit} />,
      permissions === 'MANAGER'
        ? <Resource name="invoices" show={InvoiceShow} list={InvoiceList} create={InvoiceCreate} edit={InvoiceEdit} /> 
        : <Resource name="invoices" show={InvoiceShow} list={InvoiceList} create={InvoiceCreate} />,  
      permissions === 'MANAGER'
        ? <Resource name="invoice-lines" create={InvoiceLineCreate} edit={InvoiceLineEdit} /> 
        : <Resource name="invoice-lines" />,             
      permissions === 'MANAGER'
        ? <Resource name="buyers" list={BuyerList} create={BuyerCreate} edit={BuyerEdit} />
        : <Resource name="buyers" list={BuyerList} />,

      permissions === 'MANAGER'
        ? 
          <Resource name="products" list={ProductList} create={ProductCreate} edit={ProductEdit} /> :
          <Resource name="products" list={ProductList} />,

      permissions === 'MANAGER'
        ? <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit} /> : null,
    ]}
  </Admin>
)

export default App
